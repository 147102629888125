<template>
  <div>
    <br />
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt />
        </div>

        <div class="search-wrappper">
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              placeholder="Search here..."
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <button
        type="button"
        class="btn mulai btn-secondary font-bold"
        @click="handleCreate"
      >
        <fa-icon icon="edit" class="my-icon" />&nbsp; BUAT PAKET BERLANGGANAN
        BARU
      </button>
    </div>
    <br />
    <br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(no)="row">
        {{ (currentPage - 1) * perPage + row.index + 1 }}
      </template>

      <template slot="name" slot-scope="row">{{ row.value }}</template>

      <template #cell(duration)="row">{{ 
        row.value >= 30 ? (row.value/30) + " bulan" : row.value + " hari" 
      }}</template>

      <template #cell(price)="row">{{
        formatPrice(row.value)
      }}</template>

      <template #cell(discount)="row">{{
        row.value + "%"
      }}</template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          style="margin-bottom: 2px; margin-top: 2px"
          variant="outline-secondary"
          @click="handleEdit(row.item.id)"
        >
          <fa-icon icon="pencil-alt" class="my-icon" />&nbsp;Edit
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          style="margin-bottom: 2px; margin-top: 2px"
          variant="outline-danger"
          @click="handleDelete(row.item.id)"
        >
          <fa-icon icon="trash-alt" class="my-icon" />&nbsp;Delete
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      isBusy: false,
      isLoading: false,
      items: [],
      currentItem: {
        publish: true
      },
      fields: [
        { key: "no", label: "No" },
        {
          key: "name",
          label: "Nama Paket Berlangganan",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "jenjang", label: "Jenjang" },
        { key: "duration", label: "Durasi" },
        { key: "price", label: "Harga (IDR)" },
        { key: "discount", label: "Diskon (%)" },
        { key: "actions", label: "Action" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  },

  mounted() {
    this.subscribeList();
  },

  methods: {
    ...mapActions({
      getSubscribeList: types.GET_SUBSCRIBE_LIST,
      deleteSubscribe: types.DELETE_SUBSCRIBE
    }),
    subscribeList() {
      this.isBusy = true;
      this.getSubscribeList()
        .then(response => {
          var res = response;
          this.items = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              id: res[i].id,
              name: res[i].name,
              duration: res[i].duration,
              price: res[i].price,
              discount: res[i].discount,
              access: "",
              jenjang: res[i].major? res[i].level + " " + res[i].major : res[i].level
            };
            this.items.push(dataItem);
            this.isBusy = false;
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleCreate() {
      this.$router.push("/package/add");
    },
    handleEdit(id) {
      this.$router.push("/package/edit/" + id);
    },
    handleDelete(id) {
      this.$bvModal.msgBoxConfirm('Delete this data?', {
        title: 'Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if(value) {
        this.deleteSubscribe({id:id})
          .then(response => {
          this.subscribeList();
          this.showMessageBox("Deleted", "Success", "success");
          })
          .catch(error => {
          this.showMessageBox(error, "Failed", "success");
          });
        }
      })
      .catch(err => {
        this.showMessageBox(err, "Failed", "success");
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
        })
        .catch(err => {
        });
    }
  }
};
</script>
